<template>
  <!-- 面包屑 -->
  <div class="question_bank">
    <!-- <crumbs class="breadcrumb-container" /> -->
    <transition name="fade-transform" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import Crumbs from "../../../components/crumbs"; //面包屑
export default {
  components: {
    Crumbs
  }
};
</script>

<style lang="less">
.question_bank {
  height: 100%;
  // overflow: hidden;
}
.breadcrumb-container {
  width: 100%;
  border-bottom: 1px solid rgb(217, 242, 247);
}
</style>